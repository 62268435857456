import monthlyPrices from './prices.json';
import cookie from 'react-cookies';

export const euCountryList = ['at', 'be', 'bg', 'hr',
  'cr', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'hu', 'ie',
  'it', 'lv', 'lt', 'lu', 'mt', 'aw', 'pl', 'pt', 'ro',
  'sk', 'is', 'es', 'se'];


/**
* TODO
*/
export function initializePrices() {
  let country = cookie.load('geotargetly_country_code');
  const priceRef = document
      .querySelectorAll('.geotargetlygeocontent1560145173081');
  if (euCountryList.includes(country)) {
    country = 'eu';
  }
  if (!monthlyPrices[country]) {
    country = 'us';
  }
  const countryObj = monthlyPrices[country];
  Array.from(priceRef).forEach((ref) => {
    ref.innerHTML = countryObj.cur_symbol+''+
    countryObj.price + ' /month';
  });
};
/**
* TODO
* @param {object} pageViewParameters
*/
export function trackPageView(pageViewParameters) {
  setTimeout(() => {
    window.dataLayer.push({
      'event': 'view-page-action',
      'view-page-param': {
        'pageKind': pageViewParameters.pageKind,
        'locale': pageViewParameters.locale,
        'courseSlug': pageViewParameters.courseSlug,
        'facultySlug': pageViewParameters.facultySlug,
      },
    });
  }, 50);
  cookie.save('pageKind', pageViewParameters.pageKind, {
    path: '/',
  });
};

export const getParameterByName = (name, url) => {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

/**
 *
 *
 * @export
 * @param {*} locale
 * @param {*} courseId
 * @return {*}
 */
export function getLocalizedSignupLink(locale, courseId) {
  const defaultsToEn=['en', 'en-gb', 'en-us'];
  const languagePath = defaultsToEn.includes(locale) ? '' : locale + '/';
  const signupURL = `/${languagePath}signup/?courseId=${courseId}`;
  return signupURL;
};

/**
 *
 *
 * @export
 * @param {*} locale
 * @param {*} link
 * @return {*}
 */
export function getLocalizedPageLink(locale, link) {
  const languagePath = locale === 'en' ? '' : `/${locale}`;
  const pageLink = `${languagePath}${link}`;
  return pageLink;
}
